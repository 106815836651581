/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { FaGithub, FaRegFileWord, FaLinkedinIn, FaRegEnvelope} from 'react-icons/fa';
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import resume from "../images/Resume.pdf"

//import Header from "./header"
import "./layout.css"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1rem;
  padding-top: 0;
`

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Content>
          <main>{children}
            <div id='icons'>
          <a class='github' href="https://github.com/Mcents">
            <FaGithub /></a>
          <a class='linked' href="https://www.linkedin.com/in/michael-centrelli/">
            <FaLinkedinIn /></a>
          <a class='resume' href={resume} target="_blank" rel="noopener noreferrer">
            <FaRegFileWord /></a>
          <a class='email' href = "mailto: acentrelli@gmail.com">
            <FaRegEnvelope /></a>
        </div>
        </main>
              <div class="line1"></div>
              <div class="line2"></div>
              <div class="line3"></div>
              <div class="line4"></div>
          <Footer>
          </Footer>
        </Content>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
